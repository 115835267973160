<template>
  <div class="w-full flex">
    <div class="w-full flex flex-col my-2">
      <div class="flex flex-grow flex-col mb-2">
        <span class="text-base font-semibold"> Notifications </span>
        <span class="text-carrotOrange text-sm"> ({{ length }}) </span>
      </div>

      <div
        class="w-full flex my-3"
        v-for="(notification, index) in notifications"
        :key="index"
      >
        <div class="rounded-full mr-3"
        style="width: 55px; height: 50px; background: rgba(50, 28, 59, 0.3)"
        >
          <Icon
            icon-name="bell"
            class="xsm mt-4"
            style="margin-left: 30%"
          />
        </div>

        <div class="w-full flex flex-col">
          <div class="text-sm font-semibold">
            {{ `${notification.title}` }}
          </div>
          <div class="text-xs mt-1">
            [{{ `${notification.message}` }}]
          </div>
          <div class="mt-1 flex">
          <Icon
            icon-name="icon-timer"
            class="xsm"
          />
          <span class="text-flame mt-1" style="font-size: 10px">
            {{notification.time}}
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    length: {
      type: String,
      default: "0"
    },

    notifications: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      password: ""
    };
  }

  /* mounted() {
        for(let i = 0; i < this.birthdays.length; i++) {
        const keyNames = Object.keys(this.birthdays[i]);
        console.log(keyNames[0]);
        }
    } */
};
</script>
